import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[domain]/rigel/components/nav/desktop/desktop-nav.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[domain]/rigel/components/nav/mobile/mobile-nav.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/css-variables.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/init-state.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/cookie-banner/lazy-cookie-banner.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/external-scripts/analytics.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/external-scripts/standalone.component.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/providers/index.tsx");
